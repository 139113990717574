import React from 'react';
import { Button, LayoutProvider } from '@palomina/palomina-common/components';
import { StaticImage } from 'gatsby-plugin-image';
import cn from 'classnames';
import * as styles from './styles.module.scss';

const FourOhFour = () => {
  return (
    <div className={styles.container}>
      <StaticImage
        style={{ position: 'absolute', inset: 0 }}
        src="./assets/404-bg.png"
        alt=""
      />

      <div aria-hidden className={styles.contrast} />

      <LayoutProvider padding maxWidth className={styles.content}>
        <h1
          className={cn('h1', styles.title)}
        >{`Oops.\nThis page does not exist.`}</h1>

        <Button color="grass" to="/">
          Back to Home
        </Button>
      </LayoutProvider>
    </div>
  );
};

export default FourOhFour;
