import React from 'react';
import cn from 'classnames';
import { Grid } from '@palomina/palomina-common/components';
import { SliceConfig } from '@palomina/palomina-website/components';
import * as styles from './styles.module.scss';

const TextLarge = ({ data: { sliceConfig, heading, text, link } }) => {
  const withHeading = heading && heading !== ``;

  return (
    <SliceConfig config={{ ...sliceConfig }} noPadding>
      <div className={cn(styles.container)}>
        <Grid>
          {withHeading && (
            <header className={styles.header}>
              <h2 className="b1">{heading}</h2>
            </header>
          )}

          <div className={cn(styles.text, { [styles.bordered]: withHeading })}>
            <p className="h1">{text}</p>

            {link && <p className="b1">{link.text}</p>}
          </div>
        </Grid>
      </div>
    </SliceConfig>
  );
};

export default TextLarge;
