// extracted by mini-css-extract-plugin
export var container = "styles-module--container--5f7f2";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--9ee71";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var maxWidth = "styles-module--maxWidth--36765";
export var mobile = "400px";
export var padding = "styles-module--padding--62b50";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";