import React from 'react';
import cn from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { IPalominaSettings } from '@palomina/palomina-sanity';
import { Grid, SVG } from '@palomina/palomina-common/components';

import * as styles from './styles.module.scss';

const Footer = () => {
  const siteSettings: IPalominaSettings = useStaticQuery(graphql`
    query {
      sanityPalominaSettings {
        contactEmail
        socials {
          _key
          platform
          url {
            url
          }
        }
      }
    }
  `)?.sanityPalominaSettings;

  const { contactEmail, socials } = siteSettings || {};

  return (
    <footer className={styles.container}>
      <Grid>
        <div className={styles.content}>
          {contactEmail && <p className="b1">Contact</p>}

          {contactEmail && (
            <a
              className={cn('b1', styles.contactButton)}
              href={`mailto:${contactEmail}`}
            >
              {contactEmail}
            </a>
          )}

          {socials?.[0] && (
            <div className={styles.socials}>
              {socials.map(({ _key, platform, url }) => {
                return (
                  <a
                    className={styles.socialLink}
                    key={`footer-social-link-${_key}`}
                    target="_blank"
                    href={url.url}
                  >
                    <SVG svg={platform} />
                  </a>
                );
              })}
            </div>
          )}
        </div>

        <figure className={styles.wordmark}>
          <SVG svg="wordmark" />
        </figure>
      </Grid>
    </footer>
  );
};

export default Footer;
