import React from 'react';
import cn from 'classnames';

import * as styles from './styles.module.scss';

const Card = ({ data: { _type, heading, image, description } }) => {
  let displayType = _type;

  if (_type?.includes(`website.`)) {
    switch (_type.replace(`website.`, ``)) {
      case `caseStudy`:
        displayType = `Case Study`;
        break;
      case `newsArticle`:
        displayType = `News`;
        break;
    }
  }

  return (
    <article className={styles.container}>
      {displayType && (
        <header className={styles.type}>
          <span className="b2">{displayType}</span>
        </header>
      )}

      <figure className={styles.media}>
        {image?.asset && <img src={image.asset.url} alt={image.alt} />}
      </figure>

      <div className={styles.text}>
        <h3 className={cn(styles.heading, `h6`)}>{heading}</h3>

        {description && <p className="b2">{description}</p>}
      </div>
    </article>
  );
};

export default Card;
