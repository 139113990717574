import React from 'react';
import { Link } from 'gatsby';
import { Card } from '@palomina/palomina-website/components';
import { Grid } from '@palomina/palomina-common/components';

import { SliceConfig } from '@palomina/palomina-website/components';
import * as styles from './styles.module.scss';

const ArticleGrid = ({
  data: { sliceConfig, heading, subheading, body, articles }
}) => {
  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <Grid>
          <header className={styles.header}>
            <h2 className="h3">{heading}</h2>
          </header>
        </Grid>

        <Grid>
          {articles.map((article) => {
            let prefix = ``;

            switch (article?._type) {
              case `website.caseStudy`:
                prefix = `/case-studies/`;
                break;
              case `website.newsArticle`:
                prefix = `/news/`;
                break;
              default:
                break;
            }

            return (
              <div
                key={`article-grid-${article.title}`}
                className={styles.cardContainer}
              >
                <Link to={`${prefix}${article.slug.current}`}>
                  <Card
                    data={{
                      _type: article._type,
                      heading: article.title,
                      image: article?.cardImage?.image
                    }}
                  />
                </Link>
              </div>
            );
          })}
        </Grid>
      </section>
    </SliceConfig>
  );
};

export default ArticleGrid;
