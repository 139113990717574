// extracted by mini-css-extract-plugin
export var button = "styles-module--button--cf675";
export var content = "styles-module--content--5e14b";
export var fluid = "styles-module--fluid--cc6cc";
export var icon = "styles-module--icon--02218";
export var iconButton = "styles-module--iconButton--fe861";
export var inverted = "styles-module--inverted--c8f83";
export var loader = "styles-module--loader--ccdfe";
export var loaderContainer = "styles-module--loaderContainer--180ea";
export var loading = "styles-module--loading--c75de";
export var primary = "styles-module--primary--3470b";
export var secondary = "styles-module--secondary--5e0e9";
export var spin = "styles-module--spin--bd9eb";
export var text = "styles-module--text--14a3d";