import React from 'react';
import { Grid } from '@palomina/palomina-common/components';

import { SliceConfig } from '@palomina/palomina-website/components';
import * as styles from './styles.module.scss';

const Feature = ({
  data: { sliceConfig, heading, subheading, body, media }
}) => {
  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <Grid>
          <figure className={styles.media}>
            {media?.image?.asset && (
              <img src={media.image.asset.url} alt={media.image.alt} />
            )}
          </figure>

          <article className={styles.text}>
            <div>
              <header className={styles.header}>
                <h2 className="h2">{heading}</h2>
                <h3 className="h4">{subheading}</h3>
              </header>

              <p className="b1">{body}</p>
            </div>
          </article>
        </Grid>
      </section>
    </SliceConfig>
  );
};

export default Feature;
