import React from 'react';
import cn from 'classnames';
import {
  UniversalLink,
  Grid,
  PortableTextRenderer
} from '@palomina/palomina-common/components';
import { SliceConfig } from '@palomina/palomina-website/components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { IPalominaTwoColTextImage } from '@palomina/palomina-sanity';

import * as styles from './styles.module.scss';

interface IProps {
  data: IPalominaTwoColTextImage;
}

const TwoColTextImage = ({
  data: {
    sliceConfig,
    columnType,
    textAlignment,
    caption,
    captionImage,
    _rawBody,
    bodyImage,
    mobileFullWidth,
    link,
    borderSpacing,
    showBorder,
    slicePadding: { paddingBottom, paddingTop }
  }
}: IProps) => {
  return (
    <SliceConfig config={{ ...sliceConfig }} noPadding>
      <div
        className={cn(styles.container, {
          [styles.noPaddingTop]: !paddingTop,
          [styles.noPaddingBottom]: !paddingBottom
        })}
      >
        <Grid>
          {(caption || captionImage?.asset?.gatsbyImageData) && (
            <div
              className={cn(styles.caption, {
                [styles.small]: columnType === 'asymmetric',
                [styles.alignRight]: textAlignment === 'left'
              })}
            >
              <h2 className="b1">{caption}</h2>

              {captionImage?.asset?.gatsbyImageData && (
                <figure className={styles.captionImage}>
                  <GatsbyImage
                    image={captionImage.asset.gatsbyImageData}
                    alt={captionImage?.altText || 'Palomina'}
                  />
                </figure>
              )}
            </div>
          )}

          <div
            className={cn(styles.body, {
              [styles.alignLeft]: textAlignment === 'left',
              [styles.wide]: columnType === 'asymmetric',
              [styles.fullWidth]: mobileFullWidth
            })}
          >
            <div
              className={cn(styles.text, {
                [styles.hasBorder]: showBorder,
                [styles.borderSpacing]:
                  showBorder && borderSpacing === 'regular',
                [styles.borderSpacingLrg]:
                  showBorder && borderSpacing === 'large',
                [styles.fullWidth]: mobileFullWidth
              })}
            >
              <PortableTextRenderer rawText={_rawBody} />
            </div>

            {link?.text && link?.link[0] && (
              <div className={styles.cta}>
                <UniversalLink
                  link={link}
                  theme={link?.theme}
                  iconRight={link?.icon}
                />
              </div>
            )}

            {bodyImage?.asset?.gatsbyImageData && (
              <figure className={styles.bodyImage}>
                <GatsbyImage
                  image={bodyImage.asset.gatsbyImageData}
                  alt={bodyImage?.altText || 'Palomina'}
                />
              </figure>
            )}
          </div>
        </Grid>
      </div>
    </SliceConfig>
  );
};

export default TwoColTextImage;
