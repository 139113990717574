// extracted by mini-css-extract-plugin
export var alignLeft = "styles-module--alignLeft--d2c01";
export var alignRight = "styles-module--alignRight--d8bcd";
export var body = "styles-module--body--bd8d4";
export var bodyImage = "styles-module--bodyImage--c942c";
export var borderSpacing = "styles-module--borderSpacing--3cb49";
export var borderSpacingLrg = "styles-module--borderSpacingLrg--ba84a";
export var caption = "styles-module--caption--c94ed";
export var captionImage = "styles-module--captionImage--36ed5";
export var container = "styles-module--container--1a7d6";
export var cta = "styles-module--cta--4527b";
export var desktop = "1340px";
export var fullWidth = "styles-module--fullWidth--7dca7";
export var giant = "2200px";
export var hasBorder = "styles-module--hasBorder--43745";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var noPaddingBottom = "styles-module--noPaddingBottom--74515";
export var noPaddingTop = "styles-module--noPaddingTop--c3377";
export var small = "styles-module--small--3f7d0";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--a80ee";
export var wide = "styles-module--wide--f3af4";