import { useStaticQuery, graphql } from 'gatsby';

interface ISiteMetadata {
  siteUrl: string;
}

const useSiteMetadata: () => ISiteMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query SiteMetaData {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  return site.siteMetadata;
};

export default useSiteMetadata;
