module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Palomina","short_name":"Palomina","description":"Full service production, partnering with brands to bring world class campaigns to life. Palomina. Works for you.","start_url":"/","background_color":"#C9E1E6","theme_color":"#C9E1E6","display":"standalone","icon":"src/assets/images/manifest-icon.png","include_favicon":true,"icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"8306e9b916735a5c2a9ccec7edcfeb31"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
