import React from 'react';

interface ITwitterSEOProps {
  title: string;
  description: string;
  ogImageUrl: string;
}

const TwitterSEO = ({ description, ogImageUrl, title }: ITwitterSEOProps) => {
  return (
    <>
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImageUrl} />
      <meta name="twitter:image:alt" content={title} />
    </>
  );
};

export default TwitterSEO;
