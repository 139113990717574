// extracted by mini-css-extract-plugin
export var container = "styles-module--container--01766";
export var desktop = "1340px";
export var giant = "2200px";
export var heading = "styles-module--heading--328b8";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--f9cb5";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--68413";
export var type = "styles-module--type--32ed9";