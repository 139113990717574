// extracted by mini-css-extract-plugin
export var button = "styles-module--button--613ce";
export var buttonMobile = "styles-module--buttonMobile--b225e";
export var container = "styles-module--container--bf3af";
export var content = "styles-module--content--a041d";
export var desktop = "1340px";
export var giant = "2200px";
export var headerHeight = "56px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var logo = "styles-module--logo--65bd9";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";