import React from 'react';
import * as Slices from '@palomina/palomina-website/slices';
import { ISliceConfig } from '@palomina/palomina-sanity';

interface IProps {
  data: {
    _type: string;
    sliceConfig?: ISliceConfig;
  };
  id?: string;
}

const SanitySlice = (props: IProps) => {
  const { data, id } = props;
  const { _type: sliceType } = data;

  if (!sliceType) {
    console.error(
      `SanitySlice requires a valid _type, but none was received. Check the /templates/page file to ensure a valid slice type is being passed to the SanitySlice component.`
    );

    return null;
  }

  const strippedSliceName = sliceType.replace(`palomina.`, ``);

  const sliceName =
    strippedSliceName[0].toUpperCase() +
    strippedSliceName.slice(1, strippedSliceName.length);

  const SliceSection = (Slices as any)?.[sliceName.replace(`Palomina.`, ``)];

  if (!SliceSection) {
    console.error(
      `Can't find Slice '${sliceName}', are you sure it has been configured in the slices directory?`
    );

    return null;
  }

  return (
    <section id={id} className={`slice-${sliceName}`}>
      <SliceSection {...props} />
    </section>
  );
};

export default SanitySlice;
