import React from 'react';
import cn from 'classnames';
import {
  Button,
  Grid,
  PortableTextRenderer
} from '@palomina/palomina-common/components';
import { SliceConfig } from '@palomina/palomina-website/components';
import * as styles from './styles.module.scss';

const Article = ({
  data: { sliceConfig, link, media, _rawText, textAlign }
}) => {
  let prefix;

  if (link?.link?.[0]?._type) {
    switch (link.link[0]._type) {
      case `referenceWebsiteCaseStudy`:
        prefix = `/case-studies/`;
        break;

      case `referenceWebsiteNewsArticle`:
        prefix = `/news/`;
        break;

      case `page`:
      default:
        prefix = `/`;
        break;
    }
  }

  return (
    <SliceConfig config={sliceConfig}>
      <div
        className={cn(styles.container, {
          [styles.swap]: textAlign === `left`
        })}
      >
        <Grid>
          <figure className={styles.media}>
            {media?.image?.asset && (
              <img src={media.image.asset.url} alt={media.image.alt} />
            )}
          </figure>

          <div className={styles.text}>
            <div>
              <PortableTextRenderer rawText={_rawText} />
            </div>

            {link?.link?.[0] && (
              <Button
                to={`${prefix}${link.link[0].page.slug.current}`}
                className={styles.button}
              >
                {link.text}
              </Button>
            )}
          </div>
        </Grid>
      </div>
    </SliceConfig>
  );
};

export default Article;
