// extracted by mini-css-extract-plugin
export var button = "styles-module--button--38f93";
export var container = "styles-module--container--7fafc";
export var desktop = "1340px";
export var form = "styles-module--form--4df70";
export var formInput = "styles-module--formInput--a5604";
export var giant = "2200px";
export var header = "styles-module--header--14066";
export var heading = "styles-module--heading--24794";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--a3cdc";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var subheading = "styles-module--subheading--2f532";
export var tablet = "769px";
export var text = "styles-module--text--9eb74";