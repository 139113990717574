import React from 'react';

import { ReactComponent as arrowDown } from './svgs/arrow-down.svg';
import { ReactComponent as arrowLeft } from './svgs/arrow-left.svg';
import { ReactComponent as arrowRight } from './svgs/arrow-right.svg';
import { ReactComponent as arrowUpRight } from './svgs/arrow-up-right.svg';
import { ReactComponent as lookingGlass } from './svgs/looking-glass.svg';
import { ReactComponent as minus } from './svgs/minus.svg';
import { ReactComponent as palopayWordmark } from './svgs/palopay-wordmark.svg';
import { ReactComponent as mail } from './svgs/mail.svg';
import { ReactComponent as playCircle } from './svgs/play-circle.svg';
import { ReactComponent as plus } from './svgs/plus.svg';
import { ReactComponent as warningTriangle } from './svgs/warning-triangle.svg';
import { ReactComponent as x } from './svgs/x.svg';
import { ReactComponent as undo } from './svgs/undo.svg';
import { ReactComponent as copy } from './svgs/copy.svg';
import { ReactComponent as star } from './svgs/star.svg';
import { ReactComponent as bin } from './svgs/bin.svg';
import { ReactComponent as wordmark } from './svgs/wordmark.svg';
import { ReactComponent as logo } from './svgs/logo.svg';
import { ReactComponent as hamburger } from './svgs/hamburger.svg';
import { ReactComponent as lockup } from './svgs/lockup.svg';
import { ReactComponent as linkedin } from './svgs/linkedin.svg';
import { ReactComponent as facebook } from './svgs/facebook.svg';
import { ReactComponent as instagram } from './svgs/instagram.svg';
import { ReactComponent as scribble1 } from './svgs/scribble-1.svg';
import { ReactComponent as scribble2 } from './svgs/scribble-2.svg';
import { ReactComponent as scribble3 } from './svgs/scribble-3.svg';
import { ReactComponent as chevronDown } from './svgs/chevron-down.svg';
import { ReactComponent as chevronLeft } from './svgs/chevron-left.svg';
import { ReactComponent as chevronRight } from './svgs/chevron-right.svg';
import { ReactComponent as arrowChalk1 } from './svgs/arrow-chalk-1.svg';
import { ReactComponent as arrowChalk2 } from './svgs/arrow-chalk-2.svg';
import { ReactComponent as letterA } from './svgs/letter-a.svg';
import { ReactComponent as letterD } from './svgs/letter-d.svg';
import { ReactComponent as letterE } from './svgs/letter-e.svg';
import { ReactComponent as letterFI } from './svgs/letter-fi.svg';
import { ReactComponent as letterL } from './svgs/letter-l.svg';
import { ReactComponent as letterM } from './svgs/letter-m.svg';
import { ReactComponent as letterY } from './svgs/letter-y.svg';
import { ReactComponent as bookDesktop } from './svgs/book-desktop.svg';
import { ReactComponent as bookMobile } from './svgs/book-mobile.svg';
import { ReactComponent as flower } from './svgs/flower.svg';
import { ReactComponent as clock } from './svgs/clock.svg';
import { ReactComponent as medal } from './svgs/medal.svg';
import { ReactComponent as dollarSign } from './svgs/dollar-sign.svg';
import { ReactComponent as locationPin } from './svgs/location-pin.svg';
import { ReactComponent as footerMobileLetters } from './svgs/footer-mobile-letters.svg';
import { ReactComponent as atfL } from './svgs/atf-l.svg';
import { ReactComponent as atfLRotated } from './svgs/atf-l-rotated.svg';
import { ReactComponent as atfM } from './svgs/atf-m.svg';
import { ReactComponent as atfMRotated } from './svgs/atf-m-rotated.svg';

const svgs = Object.freeze({
  atfL,
  atfLRotated,
  atfM,
  atfMRotated,
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowUpRight,
  lookingGlass,
  minus,
  palopayWordmark,
  mail,
  playCircle,
  plus,
  clock,
  medal,
  dollarSign,
  locationPin,
  hamburger,
  copy,
  bin,
  warningTriangle,
  x,
  wordmark,
  star,
  scribble1,
  scribble2,
  scribble3,
  logo,
  lockup,
  undo,
  facebook,
  instagram,
  chevronDown,
  chevronLeft,
  chevronRight,
  arrowChalk1,
  arrowChalk2,
  letterA,
  letterD,
  letterE,
  letterFI,
  letterL,
  letterM,
  letterY,
  bookDesktop,
  bookMobile,
  flower,
  footerMobileLetters,
  linkedin
});

export type TSvg = keyof typeof svgs;

interface IProps {
  svg: TSvg;
  className?: string;
  style?: React.CSSProperties;
}

const SVG = ({ svg, ...props }: IProps) => {
  if (!svgs[svg]) return null;

  const SVGElement = svgs[svg];

  return <SVGElement {...props} aria-hidden />;
};

export default SVG;
