import {useEffect, useRef} from 'react';
import {useInView} from 'react-intersection-observer';

export const useIntersectionScroll = (callback: () => void) => {
  // ---------------------------------------------------------------------------
  // import / hooks

  const {ref, inView, entry} = useInView({});

  const savedCallback = useRef(() => {});

  useEffect(() => {
    savedCallback.current = callback;
  });

  const handleScroll = () => {
    if (!entry?.target || typeof document === 'undefined' || !inView) {
      return;
    }
    
    const rect = entry.target.getBoundingClientRect();
    const sectionTop = rect.top;
  
    savedCallback.current(sectionTop);
  };

  useEffect(() => {
    if (typeof window === `undefined` || !entry?.target) {
      return () => {};
    }

    window.addEventListener(`scroll`, handleScroll, false);

    return () => {
      window.removeEventListener(`scroll`, handleScroll, false);
    };
  }, [entry, inView]);

  // ---------------------------------------------------------------------------
  // api

  return {
    ref, inView, entry
  };
};

export default useIntersectionScroll;
