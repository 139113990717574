export const remToPx = (rem: number) => {
  if (typeof window === `undefined`) {
    return rem;
  }

  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

interface IRBG {
  red: string;
  green: string;
  blue: string;
}

interface ICMYK {
  c: string;
  m: string;
  y: string;
  k: string;
}

export const hexToRGB: (hex: string) => IRBG = (hex) => {
  const hexWithoutHash = hex.replace(/^#/, '');

  // Helper function to convert to three digits
  const toThreeDigits = (num: number) => num.toString().padStart(3, '0');

  // Convert the background color to RGB
  const red = toThreeDigits(parseInt(hexWithoutHash.substring(0, 2), 16));
  const green = toThreeDigits(parseInt(hexWithoutHash.substring(2, 4), 16));
  const blue = toThreeDigits(parseInt(hexWithoutHash.substring(4, 6), 16));

  return {
    red,
    green,
    blue
  };
};

export const rgbToCMYK: (rgb: IRBG) => ICMYK = (rgb) => {
  const { blue, green, red } = rgb;

  const redNumber = parseInt(red);
  const greenNumber = parseInt(green);
  const blueNumber = parseInt(blue);

  // Convert to CMY
  let c = 1 - redNumber / 255;
  let m = 1 - greenNumber / 255;
  let y = 1 - blueNumber / 255;

  // Calculate the black key (K)
  const k = Math.min(c, Math.min(m, y));

  if (k < 1) {
    c = (c - k) / (1 - k);
    m = (m - k) / (1 - k);
    y = (y - k) / (1 - k);
  } else {
    c = 0;
    m = 0;
    y = 0;
  }

  // Convert decimals to percentages for final CMYK values
  c = Math.round(c * 100);
  m = Math.round(m * 100);
  y = Math.round(y * 100);
  const kPercent = Math.round(k * 100);

  const cString = c.toString();
  const mString = m.toString();
  const yString = y.toString();
  const kString = kPercent.toString();

  return { c: cString, m: mString, y: yString, k: kString };
};

export const capitalizeString = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const spliceFromStateArray = (array: any[], item: any) => {
  if (!array?.[0] || !array?.includes(item)) {
    return array;
  }

  const arrayClone = JSON.parse(JSON.stringify(array));

  arrayClone.splice(array.indexOf(item), 1);

  return arrayClone;
};

export const spliceFromStateArrayByProperty = (
  array: any[],
  key: string,
  value: any
) => {
  if (!array?.[0]) {
    return array;
  }

  const item = array?.find((arrayItem) => arrayItem?.[key] === value);

  if (!item) {
    return array;
  }

  return spliceFromStateArray(array, item);
};

export const spliceFromStateArrayByIndex = (array: any[], index: number) => {
  if (!array?.[0] || !array?.[index]) {
    return array;
  }

  const arrayClone = JSON.parse(JSON.stringify(array));

  arrayClone.splice(index, 1);

  return arrayClone;
};

export const getRandomIntByRange = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

export const shuffleArray = (array: any[]) => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const sortArrayByNumberProperty = (array: any[], property: string) => {
  if (!array?.[0]?.[property]) {
    return array;
  }

  return array.sort((a, b) => {
    const itemA = a?.[property] || 9999999999;
    const itemB = b?.[property] || 9999999999;

    if (itemA < itemB) {
      return -1;
    }

    if (itemA > itemB) {
      return 1;
    }

    return 0;
  });
};

export const splitCamelCase = (word: string) =>
  word.replace(/([A-Z])/g, ` $1`).replace(/^./, (str) => str.toUpperCase());

export const getCurrentLink = (pathname: string) => pathname;

export const rotisserieCamel = (camel: string) =>
  camel.replace(/([A-Z0-9])/g, `-$1`).toLowerCase();

export const camelCaseToTitleCase = (camelCase: string) => {
  const splitToWords = camelCase.replace(/([A-Z])/g, ` $1`);
  const capitalise =
    splitToWords.charAt(0).toUpperCase() + splitToWords.slice(1);
  return capitalise;
};
export const dashToCamel = (dashStr: string) =>
  dashStr.replace(/-([a-z])/g, (g) => g[1].toUpperCase());

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result as string;
      resolve(result);
    };
    reader.onerror = () => {
      reject(new Error('Error reading the file'));
    };
    reader.readAsDataURL(file);
  });
};

export const pxStringToNumber = (pxString: string) => {
  const stringWithoutPx = pxString.replace('px', '');
  const number = parseInt(stringWithoutPx);
  return number;
};

export const stringToCamelCase = (string: string) => {
  const words = string.split(' ');

  const camelCaseWords = words.map((word, i) => {
    if (i === 0) {
      return word.toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return camelCaseWords.join('');
};

export const bytesToMB: (bytes: number) => number = (bytes) => {
  return parseFloat((bytes / (1024 * 1024)).toFixed(2));
};
