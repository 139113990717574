import React from 'react';
import { Card } from '@palomina/palomina-website/components';
import { Grid } from '@palomina/palomina-common/components';

import { SliceConfig } from '@palomina/palomina-website/components';
import * as styles from './styles.module.scss';

const CardGrid = ({ data: { sliceConfig, heading, cards } }) => {
  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <Grid>
          <header className={styles.header}>
            <h2 className="h3">{heading}</h2>
          </header>
        </Grid>

        <Grid>
          {cards.map((card) => {
            return (
              <div className={styles.cardContainer}>
                <Card data={card} />
              </div>
            );
          })}
        </Grid>
      </section>
    </SliceConfig>
  );
};

export default CardGrid;
