// extracted by mini-css-extract-plugin
export var container = "styles-module--container--f0142";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--77fea";
export var large = "styles-module--large--fee2c";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--bdf76";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var wordmark = "styles-module--wordmark--e133e";