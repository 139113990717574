import React, { useRef } from 'react';
import cn from 'classnames';
import gsap from 'gsap';
import { Grid, SVG } from '@palomina/palomina-common/components';
import {
  useBreakpoints,
  useIntersectionScroll
} from '@palomina/palomina-common/hooks';

import { SliceConfig } from '@palomina/palomina-website/components';
import * as styles from './styles.module.scss';

const Media = ({ data: { sliceConfig, media, withLogo } }) => {
  const mediaRef = useRef();

  const { largeTablet } = useBreakpoints();

  const { ref } = useIntersectionScroll((sectionTop) => {
    const y = parseInt(-sectionTop / (largeTablet ? 10 : 15));

    gsap.to(mediaRef.current, {
      duration: 0,
      y
    });
  });

  return (
    <SliceConfig config={sliceConfig}>
      <section ref={ref} className={styles.container}>
        <figure ref={mediaRef} className={styles.media}>
          {(media?.video?.url && (
            <video autoPlay muted loop playsInline>
              <source src={media.video.url} type="video/mp4" />
            </video>
          )) || (
            <>
              {media?.image?.asset && (
                <img src={media.image.asset.url} alt={media.image.alt} />
              )}
            </>
          )}
        </figure>

        {withLogo && (
          <Grid>
            <article className={styles.content}>
              <SVG svg="wordmark" />
            </article>
          </Grid>
        )}
      </section>
    </SliceConfig>
  );
};

export default Media;
