import React from 'react';
import cn from 'classnames';
import {
  Grid,
  PortableTextRenderer
} from '@palomina/palomina-common/components';
import { SliceConfig } from '@palomina/palomina-website/components';
import * as styles from './styles.module.scss';

const RichText = ({ data: { sliceConfig, _rawText } }) => {
  return (
    <SliceConfig config={sliceConfig}>
      <div className={cn(styles.container)}>
        <Grid>
          <div className={styles.text}>
            <div>
              <PortableTextRenderer rawText={_rawText} />
            </div>
          </div>
        </Grid>
      </div>
    </SliceConfig>
  );
};

export default RichText;
