import React from 'react';
import cn from 'classnames';
import { Button, Grid } from '@palomina/palomina-common/components';
import { SliceConfig } from '@palomina/palomina-website/components';
import * as styles from './styles.module.scss';

const TextSmall = ({ data: { sliceConfig, heading, text, link } }) => {
  return (
    <SliceConfig config={{ ...sliceConfig }} noPadding>
      <div className={cn(styles.container)}>
        <Grid>
          <header className={styles.header}>
            <h2 className="b1">{heading}</h2>
          </header>

          <div className={cn(styles.text)}>
            <p className="b1">{text}</p>
          </div>

          {link && (
            <div className={cn(styles.cta)}>
              {link.text}
              {/* <Button>{link.text}</Button> */}
            </div>
          )}
        </Grid>
      </div>
    </SliceConfig>
  );
};

export default TextSmall;
