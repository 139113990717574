import React from 'react';
import cn from 'classnames';
import { Button, Grid } from '@palomina/palomina-common/components';

import { SliceConfig } from '@palomina/palomina-website/components';
import * as styles from './styles.module.scss';

const LeadCapture = ({
  data: { sliceConfig, heading, subheading, body, media }
}) => {
  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <Grid>
          <figure className={styles.media}>
            {media?.image?.asset && (
              <img src={media.image.asset.url} alt={media.image.alt} />
            )}
          </figure>

          <article className={styles.text}>
            <header className={styles.header}>
              <h2 className={cn(styles.heading, `h1`)}>{heading}</h2>
              <h3 className={cn(styles.subheading, `h4`)}>{subheading}</h3>
            </header>

            <p className="b1">{body}</p>

            <form className={styles.form}>
              <input
                className={styles.formInput}
                style={{
                  borderBottom: `1px solid ${sliceConfig.textColor.hex}`
                }}
                name="name"
                type="text"
                placeholder="Name"
              />

              <input
                className={styles.formInput}
                style={{
                  borderBottom: `1px solid ${sliceConfig.textColor.hex}`
                }}
                name="email"
                type="email"
                placeholder="Email Address"
              />

              <Button className={styles.button}>
                <span className="b1">Submit</span>
              </Button>
            </form>
          </article>
        </Grid>
      </section>
    </SliceConfig>
  );
};

export default LeadCapture;
