import { useCallback, useEffect } from 'react';

const useTrapFocus = (
  isVisible: boolean,
  containerRef: React.MutableRefObject<HTMLDivElement | null>,
  bypass?: boolean
) => {
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!containerRef.current) return;

      const container = containerRef.current;
      const focusableElementTypes = `button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])`;
      const focusableContentNodesList = container.querySelectorAll(
        focusableElementTypes
      ) as NodeListOf<any>;
      const focusableElements = Array.from(focusableContentNodesList).filter(
        // <a> tags aren't 'disabled' but will have the attribute
        (element) =>
          !element.disabled &&
          !element.hasAttribute(`disabled`) &&
          !element.classList.contains(`honeypot-input`)
      );
      const firstFocusableElement = focusableElements[0] as any;
      const lastFocusableElement = focusableElements[
        focusableElements.length - 1
      ] as any;

      if (e.key !== `Tab`) return;

      let isContainerElementFocused = false;

      focusableElements.forEach((element) => {
        if (element === document.activeElement)
          isContainerElementFocused = true;
      });

      if (e.shiftKey && document.activeElement === firstFocusableElement) {
        e.preventDefault();
        lastFocusableElement.focus();
      } else if (
        !e.shiftKey &&
        document.activeElement === lastFocusableElement
      ) {
        e.preventDefault();
        firstFocusableElement.focus();
      } else if (!isContainerElementFocused) {
        e.preventDefault();
        firstFocusableElement.focus();
      }
    },
    [containerRef]
  );
  useEffect(() => {
    if (isVisible && !bypass) {
      window.addEventListener(`keydown`, handleKeyDown);
    } else {
      window.removeEventListener(`keydown`, handleKeyDown);
    }
    return () => window.removeEventListener(`keydown`, handleKeyDown);
  }, [isVisible, bypass, handleKeyDown]);
};

export default useTrapFocus;
