import React from 'react';

interface IFacebookSEOProps {
  title: string;
  siteUrl: string;
  description: string;
  ogImageUrl: string;
}

const FacebookSEO = ({
  title,
  siteUrl,
  description,
  ogImageUrl
}: IFacebookSEOProps) => {
  return (
    <>
      <meta property="og:site_name" content={title} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImageUrl} />
      <meta property="og:image:alt" content={title} />
    </>
  );
};

export default FacebookSEO;
